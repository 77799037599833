<template>
	<div class="distributionOrder">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-select v-model:value="search.type" placeholder="订单类型" style="width: 150px;">
					<a-select-option value="1">商城</a-select-option>
					<a-select-option value="2">认养</a-select-option>
					<a-select-option value="3">土地</a-select-option>
				</a-select>
				<a-input placeholder="收货人" v-model:value="search.name" style="width: 200px;"></a-input>
				<a-input placeholder="联系电话" v-model:value="search.mobile" style="width: 200px;"></a-input>
				<a-range-picker v-model:value="search.time" />
				<a-button type="primary" @click="getOrderList(1,info.limit)">查询</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table class="tb-overflow" :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'订单信息',dataIndex:'order',slots:{customRender:'order'},width:210},
				{title:'商品信息',dataIndex:'goods',slots:{customRender:'goods'},width:400},
				{title:'价格信息',dataIndex:'price',slots:{customRender:'price'},width:120},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'},width:120},
				{title:'分销情况',dataIndex:'dis',slots:{customRender:'dis'},width:260},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'},width:160},
			]" :scroll="{x:1,y:480}">
				<template #order="{record}">
					<a-tag :color="orderType(record.type,2)">{{orderType(record.type,1)}}</a-tag>
					<div><span class="f12">ID：</span>{{record.id}}</div>
					<div><span class="f12">订单编号：</span>{{record.order_details.order_number}}</div>
					<div><span class="f12">订单时间：</span>{{record.order_details.create_time}}</div>
				</template>
				<template #goods="{record}">
					<div v-if="record.type == 1">
						<div class="kou-goods" v-for="(val,key) in record.order_details.detail" :key="key">
							<a-image style="width:70px;height:70px;" v-if="val.cover" :src="val.cover"/>
							<div class="kou-goods-info">
								<div class="name">{{val.goods_name}}</div>
								<div class="spec text-gray"  v-if="val.snap_goods_spec">
									<span v-for="(spec,spec_ind) in val.snap_goods_spec.sku" :key="spec_ind">{{spec.spec_value}};</span>
								</div>
								<div class="spec">数量：x{{val.count}}</div>
								<div class="name text-gray">小计：<span style="color: rgb(234, 8, 8);">￥{{val.count*val.price}}</span></div>
							</div>
						</div>
					</div>
					<div v-if="record.type ==2">
						<div class="kou-goods">
							<a-image style="width:70px;height:70px;" :src="record.order_details.adopt.cover"/>
							<div class="kou-goods-info">
								<div class="name">{{record.order_details.adopt.name}}</div>
								<div class="spec">数量：x{{record.order_details.buy_count}}{{record.order_details.adopt.unit}}</div>
							</div>
						</div>
					</div>
					<div v-if="record.type == 3">
						<div class="kou-goods">
                            <a-image style="width:70px;height:70px;" v-if="record.order_details.land" :src="record.order_details.land.cover"/>
                            <div class="kou-goods-info" v-if="record.order_details.land">
                                <div class="name">{{record.order_details.land.land_name}}</div>
                                <div class="spec">土地编号：{{record.order_details.spec.land_num}}</div>
                                <div class="spec">面积：x{{record.order_details.spec.area}}㎡</div>
                            </div>
                        </div>
					</div>
				</template>
				<template #price="{record}">
					{{record.order_details.total_price}}元
				</template>
				<template #status="{record}">
					<div><span class="f12">支付状态：</span>{{payStatus(record.orders.pay_status,1)}}</div>
					<div v-if="record.orders"> <span class="f12">发货状态：</span>{{sendStatus(record.orders.is_send,1)}}</div>
				</template>
				<template #dis="{record}">
					<div v-if="record.one_user">
						<div v-if="record.one_user"><span class="f12">昵称：</span>{{record.one_user.nickname}}</div>
						<div><span class="f12">一级佣金：</span>￥{{record.orders.one_price}}</div>
					</div>
					<div v-if="record.two_user">
						<div><span class="f12">昵称：</span>：{{record.two_user.nickname}}</div>
						<div><span class="f12">二级佣金：</span>￥{{record.orders.two_price}}</div>
					</div>
				</template>
				<template #action="{record}">
					<a-space>
						<a-button size="small" @click="showPriceDetail(record)">佣金详情</a-button>
						<a-button size="small" type="primary" v-if="record.status == 1" @click="sendPrice(record.id)">发放佣金</a-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="info.page"
				    :total="info.count"
					:default-page-size="info.limit"
				    @showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
					@change="(e)=>{getOrderList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.priceDetail" title="佣金详情" :width="700" :footer="null" @cancel="show.priceDetail = false">
			<a-table :pagination="false" :row-key="(r,i)=>{ return i.toString()}" :data-source="priceDetail.list" :columns="[
				{title:'姓名',dataIndex:'name',slots:{customRender:'name'}},
				{title:'奖励类型',dataIndex:'type',slots:{customRender:'type'}},
				{title:'奖励佣金',dataIndex:'dis_price'},
			]">
			<template #name="{record}">
				<span v-if="record.account">
					{{record.account.nickname}}(UID:{{record.account.user_id}})
				</span>
			</template>
			<template #type="{record}">
				<span v-if="record.is_one == 1">一级奖励</span>
				<span v-if="record.is_one == 2">二级奖励</span>
				<span v-if="record.is_one == 3">团队奖励</span>
			</template>
			</a-table>
			<div class="total">
				<div>
					<span>总分销金额：</span>
					<span class="price">{{priceDetail.total}}元</span>
				</div>
				<span v-if="priceDetail.user">发放人：{{priceDetail.user.user.name}}</span>
				<span v-if="priceDetail.user">发放时间：{{priceDetail.user.create_time}}</span>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution.js'
export default{
	setup(){
		const _d = reactive({
			search:{
				type:'1',
				name:'',
				mobile:'',
				time:[],
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			show:{priceDetail:false},
			priceDetail:{
				list:[],
				total:0,
				user:"",//发放人
			},
		})
		getOrderList(1,_d.info.limit)
		function getOrderList(page,limit){
			distributionModel.getDisOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}

		const sendPrice = (id)=>distributionModel.setDisSendPrice(id,()=>getOrderList(_d.info.page,_d.info.limit))
		
		const orderType = computed(()=>{
			return function(e,t){
				return t==1 ? (e==1?'商城':(e==2?'认养':'土地')) :(e==1?'#FF0066':(e==2?'#0099FF':'#00CC66'))
			}
		})
		const payStatus = computed(()=>{
			return function(e,t){
				return t==1?(e==0?'未支付':'已支付') :(e==0?'#999':'#00CC66')
			}
		})
		const sendStatus = computed(()=>{
			return function(e,t){
				return t==1?(e==0?'未发货':'已发货') :(e==0?'#999':'#00CC66')
			}
		})

		function showPriceDetail(row){
			_d.show.priceDetail = true
			_d.priceDetail.list = row.pricelog
			_d.priceDetail.user = (row.handlers && row.handlers.length >0 ) ? row.handlers[0] :null
			
			let total = 0
			row.pricelog.forEach(item=>{
				total+=parseFloat(item.dis_price)
			})

			_d.priceDetail.total = total.toFixed(2)
		}

		return{
			...toRefs(_d),
			getOrderList,
			sendPrice,
			orderType,
			payStatus,
			sendStatus,
			showPriceDetail
		}
	},
}
</script>
<style lang="scss" scoped>
.kdo-order-title{
	width: 100%;
	height: 40px;
	line-height: 40px;
	border: 1px solid #f4f4f4;
	display: flex;
	
	.kdot-item{
		border-right: 1px solid #F4F4F4;
		font-weight: bold;
		font-size: 14px;
	}
	.kdot-item:not(:first-child){
		text-align: center;
	}
}
.kdo-container{
	width: 100%;
	border: 1px solid #f4f4f4;
	margin-top: 10px;
	cursor: pointer;
	&-header{
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		border-bottom: 1px solid #F4F4F4;
		display: flex;
		justify-content: space-between;
		padding: 0 24px;
		box-sizing: border-box;
	}
	
	&-footer{
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
		border-top: 1px solid #F4F4F4;
		span{
			margin-left: 10px;
		}
	}
}
.kou-goods{
	display: flex;
	font-size: 12px;
	margin-top: 10px;
	border-bottom: 1px solid #F4F4F4;
	padding: 10px;

	&-info{
		width: 70%;
		margin-left: 12px;
	}
}
.kdo-container:hover{
	border: 1px solid #409eff;
}
.total{
	font-weight: bold;	
	margin-top: 12px;	
	display: flex;
	justify-content: space-between;
	.price{
		color: red;
	}
}

</style>
